import React from 'react';
import './favicon.scss';

const Favicon = () => {

  return (
    <div className="favicon">
      <div className="favicon-elm">
        <div>
          <span className="v">V</span>
          <span className="b">B</span>
        </div>
      </div>
      <div className="favicon-elm admin">
        <div>
          <span className="v">V</span>
          <span className="b">B</span>
          <span className="a">
            <span>A</span>
          </span>
        </div>
      </div>
    </div>
  );

};

export default Favicon;