import React from 'react';
import './green-machine.scss';
import * as DateUtil from "../../../utils/date";

import Table from '../../elements/table/table';

const GreenMachine = () => {

  const sideItems = [];

  const columns = [
    {table: "greenMachine",value: "id",index:true,},
    {table: "greenMachine",value: "light",},
    {table: "greenMachine",value: "moisture",},
    {table: "greenMachine",value: "temperature",},
    {table: "greenMachine",value: "createdDate",index:true, type:"date", defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="green-machine">
      <h3>Green Machine</h3>
      <Table 
        database={"Main"}
        displayName="Feedback"
        name="FeedbackTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // setShowValue(value);
          // setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />
    </div>
  );

};

export default GreenMachine;