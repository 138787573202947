export const common = {
  edit: {
    en: "Edit",
    ja: "編集",
    et: "Muuda",
  },
  save: {
    en: "Save",
    ja: "保存",
    et: "Salvesta",
  },
  create:{
    en: "Create",
    ja: "作成",
    et: "Loo",
  },
  remove:{
    en: "Remove",
    ja: "解除",
    et: "Kustuta",
  },
  requestErr: {
    en: "Request Error: Refresh the page to try again. Contact us if this continues. ",
    ja: "リクエストエラー：ページを更新して再試行してください。 これが続く場合はお問い合わせください。",
    et: "Päringu viga: Lae leht uuesti. Võta ühendust, kui see viga jätkub."
  },
  resultsCount: {
    "en": "Showing {{start}}-{{stop}} of {{totalRes}} Results",
    "ja": "{{totalRes}} 件中 {{start}} - {{stop}} 件を表示中"
  },
  noResults:{
    "en": "No Results",
    "ja": "結果がありません"
  },


}