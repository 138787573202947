import React,{useState,useEffect} from 'react';
import './modify-article-modal.scss';
import './article-item.scss';
import Modal from '../../common/modal/modal';
import ConfirmModal from '../../common/modal/confirm-modal';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {toast} from 'react-toastify';
import {request} from '../../../utils/request';
import ChatGptModel from '../../../enums/chatgpt-modal';
import usePersistState from '../../../utils/use-persist-state';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import CopyInput from '../../elements/copy-input/copy-input';
import Config from '../../../config/config';
import * as DateUtil from '../../../utils/date';

let ArticlePublishEnum = {
  0:"Unpublished",
  1:"Published",
  2:"WIP",
}

const ModifyArticleModal = ({show,handleClose,value,callback}) => {

  let [slide,setSlide] = useState(1);
  let [loading,setLoading] = useState(false);
  let [uid,setUid] = useState(0);
  let [imageUrl,setImageUrl] = useState("");
  let [imageText,setImageText] = useState("");
  let [author,setAuthor] = useState("");
  let [title,setTitle] = useState("");
  let [metaDesc,setMetaDesc] = useState("");
  let [metaKeywords,setMetaKeywords] = useState("");
  let [json,setJson] = useState("");
  let [article,setArticle] = useState("");
  let [articleDetailPrompt,setArticleDetailPrompt] = useState("");
  let [pubDate,setPubDate] = useState("");
  let [urlPath,setUrlPath] = useState("");
  let [model,setModel] = usePersistState(ChatGptModel.ChatGpt4Turbo,"ModifyArticleModalModel");
  let [uploadImageUrl,setUploadImageUrl] = useState("");
  let [newImageUrl,setNewImageUrl] = useState("");
  let [genArticlePrompt,setGenArticlePrompt] = useState("");
  let [pubStatus,setPubStatus] = useState(0);

  let [confirmDeleteArtileUid,setConfirmDeleteArtileUid] = useState(null);

  useEffect(() => {
    let newUid = "";
    let newImageUrl = "";
    let newImageText = "";
    let newAuthor = "";
    let newKeywords = "";
    let newTitle = "";
    let newDesc = "";
    let newJson = "";
    let newArticle = "";
    let newArticleDetailPrompt = "";
    let newPubDate = "";
    let newUrlPath = "";
    let newPubStatus = 0;
    try{
      console.log("value",value);
      newUid = value['articlesUid'];
      newImageUrl = value['articlesImageUrl'];
      newImageText = value['articlesImageText'];
      newAuthor = value['articlesAuthor'];
      newTitle = value['articlesTitle'];
      newDesc = value['articlesMetaDesc'];
      newKeywords = value['articlesMetaKeywords'];
      newJson = value['articlesJson'];
      newArticle = value['articlesArticle'];
      newArticleDetailPrompt = value['articlesArticleDetailPrompt']
      newUrlPath = value['articlesUrlPath'];
      newPubStatus = value['articlesPublished'];

    }catch(e){
      // console.log("e",e);
    }
    if(newAuthor === ""){
      newAuthor = "Trevor";
    }
    try{
      // 2024-03-04
      newPubDate = new Date(value['articlesPublishedDate']);
      let month = newPubDate.getMonth() + 1;
      newPubDate = newPubDate.getFullYear()+"-"+(month<=9?"0":"")+month+"-"+(newPubDate.getDate()<=9?"0":"")+newPubDate.getDate();
    }catch(e){
      console.log("e",e);
    }
    setUid(newUid);
    setImageUrl(newImageUrl);
    setImageText(newImageText);
    setAuthor(newAuthor);
    setTitle(newTitle);
    setMetaDesc(newDesc);
    setMetaKeywords(newKeywords);
    setJson(newJson)
    setArticle(newArticle);
    setArticleDetailPrompt(newArticleDetailPrompt)
    setPubDate(newPubDate);
    setUrlPath(newUrlPath);
    setPubStatus(newPubStatus);
  },[value]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    saveArticle();
  }

  const saveArticle = () => {
    let body = {uid,title,metaDesc,metaKeywords,article,articleDetailPrompt,json,author,imageUrl,imageText,pubDate,urlPath};
    setLoading(true);
    request("update-article","/update-article","POST", body, {
      then: function(res){
        toast.success("Saved")
        if(callback){callback();}
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const handleImageSubmit = (e) => {
    e.preventDefault();
    uploadImage();
  }

  const uploadImage = () => {
    let body = {uploadImageUrl};
    setLoading(true);
    request("upload-image-url","/upload-image-url","POST", body, {
      then: function(res){
        setNewImageUrl(res.data.res.newImageUrl);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }
  

  const convertArticle = () => {
    let body = {uid,article,model}
    setLoading(true);
    request("convert-article","/convert-article","POST", body, {
      then: function(res){
        let newJson = (res.data.res.json)?res.data.res.json:"";
        setJson(newJson);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const publishArticle = (newPubStatus) => {
    let body = {uid,newPubStatus}
    setLoading(true);
    request("publish-article","/publish-article","POST", body, {
      then: function(res){
        setPubStatus(newPubStatus);
        toast.success("Publish Toggled");
        if(callback){callback();}
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const deleteArticle = (uid) => {
    let body = {uid}
    setLoading(true);
    request("delete-article","/delete-article","DELETE", body, {
      then: function(res){
        toast.success("Article Deleted");
        if(handleClose){handleClose()};
        if(callback){callback();}
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const generateArticleDetailsFromPrompt = (uid,articleDetailPrompt,title,prompt,model) => {
    let body = {uid,articleDetailPrompt,title,prompt,model}
    setLoading(true);
    request("generate-article-from-prompt","/generate-article-from-prompt","POST", body, {
      then: function(res){
        let json = res.data.res.json;

        let newArticle = "";
        let newImagePrompt = "";
        let newMetaDesc = "";
        let newMetaKeywords = "";
        let newUrlPathName = "";
        try{
          let parsedJson = JSON.parse(json);
          console.log("parsedJson",parsedJson);

          newArticle = parsedJson.article
          newImagePrompt = parsedJson.imagePrompt
          newMetaDesc = parsedJson.metaDesc
          newMetaKeywords = parsedJson.metaKeywords
          newUrlPathName = parsedJson.urlPathName

        }catch(e){
          alert("failed to parse json")
          console.log("e",e);
        }

        setImageText(newImagePrompt);
        setMetaDesc(newMetaDesc);
        setMetaKeywords(newMetaKeywords);
        setArticle(newArticle);
        setUrlPath(newUrlPathName);

      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  let parseJson = false;
  if(json !== ""){
    try{
      parseJson = JSON.parse(json);
    }catch(e){
      console.log("e",e);
    }
  }

  // console.log("json",json);
  // console.log("parseJson",parseJson);


  const renderElementSections = (obj) => {
    // Mapping of custom types to HTML tags

    const tagMap = {
      paragraph: 'p',
      header: 'h2', // Assuming headers within sections are h2
    };
  
    if (Array.isArray(obj)) {
      // Render array of elements (e.g., sections or content arrays)
      return (
        <>
          {obj.map((item, index) => renderElementSections(item))}
        </>
      );
    } else if (obj !== null && typeof obj === 'object') {
      // Render section or content object
      if (obj.hasOwnProperty('header') && obj.hasOwnProperty('content')) {
        // Render section with a header and content
        return (
          <section key={obj.header}>
            <h2>{obj.header}</h2>
            {renderElementSections(obj.content)}
          </section>
        );
      } else if (obj.hasOwnProperty('type') && obj.hasOwnProperty('text')) {
        // Render content item (e.g., paragraph)
        const Tag = tagMap[obj.type] || 'div'; // Default to 'div' if type is not recognized
        return <Tag key={obj.text}>{obj.text}</Tag>;
      }
    } else {
      // Render primitive values, though not expected in this structure
      return <div>1{obj}</div>;
    }
  };

  // .article.sections

  const renderArticle = ({title,desc,author,date,image,imageAlt,sections}) => {

    return(
      <div className='article-item'>
        <div className='article-top-div'>
          <div>Author: {author}</div>
          <div>Date: {DateUtil.dateTimeSince(date)}</div>
        </div>
        <div className='article-image-div'>
          <img src={image.replace("{{cdn}}",Config.Common.CdnUrl)} alt={imageAlt} />
        </div>
        <div className='article-title-div'>
          <h1>{title}</h1>
          <p>{desc}</p>
        </div>
        {renderElementSections(sections)}
      </div>
    )
  }  

  return (
    <Modal className="modify-article-modal" show={show} handleClose={handleClose}>
      <div>
        <CarouselHeader slideTo={slide}>
          <div onClick={() => {setSlide(1)}}>Inputs</div>
          <div onClick={() => {setSlide(2)}}>Upload Image</div>
          <div onClick={() => {setSlide(3)}}>Preview</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div className='modify-article-inputs'>
            <form onSubmit={handleSubmit}>
              <div className="buttons top">
                <div className="left">
                  <Button type="button" status={loading?"loading":"up"} onClick={() => {
                    publishArticle(1);
                  }}>Publish</Button>
                  <Button type="button" status={loading?"loading":"up"} onClick={() => {
                    publishArticle(2);
                  }}>WIP</Button>
                  <Button type="button" status={loading?"loading":"up"} onClick={() => {
                    publishArticle(0);
                  }}>Unpublish</Button>
                  <div>
                    Status: {ArticlePublishEnum[pubStatus]}
                  </div>
                </div>
                <div className="right">
                  <Button type="submit" status={loading?"loading":"save"} value="Save"/>
                </div>
              </div>
              <div className='row'>
                <div>
                  <label>Title</label>
                  <input type="text" value={title ?? ""} onChange={(e) => {setTitle(e.target.value)}}/>
                </div>
                <div>
                  <label>Gen Article Prompt</label>
                  <Textarea value={genArticlePrompt ?? ""} onChange={(e) => {setGenArticlePrompt(e.target.value)}}></Textarea>
                </div>
              </div>
              <div>
                <label>Article Detail Prompt - <span className='note'>Also uses Title</span></label>
                <div className='flex'>
                  <div className='fill-flex'>
                    <Textarea value={articleDetailPrompt ?? ""} onChange={(e) => {setArticleDetailPrompt(e.target.value)}}></Textarea>
                  </div>
                  <div className='button-div'>
                    <Button type="button" status={loading?"loading":"forward"} onClick={() => {
                      generateArticleDetailsFromPrompt(uid,articleDetailPrompt,title,genArticlePrompt,model);
                    }}>Generate</Button>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div>
                  <label>Author</label>
                  <input type="text" value={author ?? ""} onChange={(e) => {setAuthor(e.target.value)}}/>
                </div>
                <div>
                  <label>Published Date</label>
                  <input type="date" value={pubDate ?? ""} onChange={(e) => {setPubDate(e.target.value)}} />
                </div>
              </div>
              <div className='row'>
                <div>
                  <label>Image Url</label>
                  <input type="text" value={imageUrl ?? ""} onChange={(e) => {setImageUrl(e.target.value)}}/>
                </div>
                <div>
                  <label>Image Text</label>
                  <input type="text" value={imageText ?? ""} onChange={(e) => {setImageText(e.target.value)}}/>
                </div>
              </div>
              <div>
                <label>Url Path</label>
                <input type="text" value={urlPath ?? ""} onChange={(e) => {setUrlPath(e.target.value)}}/>
              </div>
              <div>
                <label>Meta Desc</label>
                <Textarea value={metaDesc ?? ""} onChange={(e) => {setMetaDesc(e.target.value)}}></Textarea>
              </div>
              <div>
                <label>Meta Keywords</label>
                <Textarea value={metaKeywords ?? ""} onChange={(e) => {setMetaKeywords(e.target.value)}}></Textarea>
              </div>
              <div>
                <label>Article</label>
                <Textarea value={article ?? ""} onChange={(e) => {setArticle(e.target.value)}}></Textarea>
              </div>
              
              <div className="buttons">
                <div className="left"></div>
                <div className="right">
                  <select className='model-select' value={model} onChange={(e) => {setModel(e.target.value)}}>
                    {
                      Object.keys(ChatGptModel).map((keyName, keyIndex) => {
                        return(
                          <option key={keyIndex} value={ChatGptModel[keyName]}> {keyName}</option>
                        )
                      })
                    }
                  </select>
                  <Button type="button" status={loading?"loading":"forward"} onClick={() => {
                    convertArticle();
                  }}>Convert Article</Button>
                </div>
              </div>
              <div>
                <label>Json</label>
                <Textarea value={json ?? ""} onChange={(e) => {setJson(e.target.value)}}></Textarea>
              </div>
              <div className="buttons">
                <div className="left">
                  <Button type="button" status={loading?"loading":"up"} onClick={() => {
                    publishArticle();
                  }}>Publish Article</Button>
                  <Button parentClassName={"delete"} type="button" status={loading?"loading":"delete"} onClick={() => {
                    setConfirmDeleteArtileUid(uid)
                  }}>Delete Article</Button>
                </div>
                <div className="right">
                  <Button type="submit" status={loading?"loading":"save"} value="Save"/>
                </div>
              </div>
            </form>
          </div>
          <div className="modify-article-upload-image">
            <div>
              <form onSubmit={handleImageSubmit}>
                <div>
                  <label>Image Url</label>
                  <input type="text" value={uploadImageUrl} onChange={(e) => {setUploadImageUrl(e.target.value)}} />
                </div>
                <div className="buttons">
                  <div className="left"></div>
                  <div className="right">
                    <Button type="submit" status={loading?"loading":"save"} value="Upload"/>
                  </div>
                </div>
              </form>
              {(newImageUrl !== "")?
              <div className='new-image-url-results'>
                <CopyInput display={"New Url"} value={newImageUrl}/>
                <div className='image'>
                  <img src={newImageUrl.replace("{{cdn}}",Config.Common.CdnUrl)} />
                </div>
              </div>
              :null}
            </div>
          </div>
          <div className="modify-article-preview">
            {(parseJson)?
            <div>
              {renderArticle({
                title:title,
                desc:metaDesc,
                author:author,
                date:pubDate,
                image:imageUrl,
                imageAlt:imageText,
                sections: parseJson.article.sections
              })}
            </div>:null}
          </div>
        </Carousel>

        <ConfirmModal show={confirmDeleteArtileUid} handleClose={(confirmed) => {
          if(confirmed){
            deleteArticle(confirmDeleteArtileUid)
          }
          setConfirmDeleteArtileUid(null)
        }}>
          <div>
            Are you certain?
          </div>
        </ConfirmModal>
        
      </div>
    </Modal>
  );
};

export default ModifyArticleModal;