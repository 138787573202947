export const time = {
  "seconds-ago": {
    "en": "{{value}} Seconds Ago",
    "ja": "{{value}} 秒前"
  },
  "second-ago": {
    "en": "{{value}} Second Ago",
    "ja": "{{value}} 秒前"
  },
  "minutes-ago": {
    "en": "{{value}} Minutes Ago",
    "ja": "{{value}} 分前"
  },
  "minute-ago": {
    "en": "{{value}} Minute Ago",
    "ja": "{{value}} 分前"
  },
  "hours-ago": {
    "en": "{{value}} Hours Ago",
    "ja": "{{value}} 時間前"
  },
  "hour-ago": {
    "en": "{{value}} Hour Ago",
    "ja": "{{value}} 時間前"
  },
  "days-ago": {
    "en": "{{value}} Days Ago",
    "ja": "{{value}} 日前"
  },
  "day-ago": {  
    "en": "{{value}} Day Ago",
    "ja": "{{value}} 日前"
  },
  "now": {
    "en": "Now",
    "ja": "今"
  },

  "month-1": {
    "en": "January",
    "ja": "1月",
    "et": "Jaanuar"
  },
  "month-2": {
    "en": "February",
    "ja": "2月",
    "et": "Veebruar"
  },
  "month-3": {
    "en": "March",
    "ja": "3月",
    "et": "Märts"
  },
  "month-4": {
    "en": "April",
    "ja": "4月",
    "et": "Aprill"
  },
  "month-5": {
    "en": "May",
    "ja": "5月",
    "et": "Mai"
  },
  "month-6": {
    "en": "June",
    "ja": "6月",
    "et": "Juuni"
  },
  "month-7": {
    "en": "July",
    "ja": "7月",
    "et": "Juuli"
  },
  "month-8": {
    "en": "August",
    "ja": "8月",
    "et": "August"
  },
  "month-9": {
    "en": "September",
    "ja": "9月",
    "et": "September"
  },
  "month-10": {
    "en": "October",
    "ja": "10月",
    "et": "Oktoober"
  },
  "month-11": {
    "en": "November",
    "ja": "11月",
    "et": "November"
  },
  "month-12": {
    "en": "December",
    "ja": "12月",
    "et": "Detsember"
  },

  "short-month-1": {
    "en": "Jan",
    "ja": "1月",
    "et": "Jaan"
  },
  "short-month-2": {
    "en": "Feb",
    "ja": "2月",
    "et": "Veebr"
  },
  "short-month-3": {
    "en": "Mar",
    "ja": "3月",
    "et": "Märts"
  },
  "short-month-4": {
    "en": "Apr",
    "ja": "4月",
    "et": "Apr"
  },
  "short-month-5": {
    "en": "May",
    "ja": "5月",
    "et": "Mai"
  },
  "short-month-6": {
    "en": "Jun",
    "ja": "6月",
    "et": "Juuni"
  },
  "short-month-7": {
    "en": "Jul",
    "ja": "7月",
    "et": "Juuli"
  },
  "short-month-8": {
    "en": "Aug",
    "ja": "8月",
    "et": "Aug"
  },
  "short-month-9": {
    "en": "Sep",
    "ja": "9月",
    "et": "Sept"
  },
  "short-month-10": {
    "en": "Oct",
    "ja": "19月",
    "et": "Okt"
  },
  "short-month-11": {
    "en": "Nov",
    "ja": "11月",
    "et": "Nov"
  },
  "short-month-12": {
    "en": "Dec",
    "ja": "12月",
    "et": "Dets"
  },
  "short-day": {
    "en": "",
    "ja": "日",
    "et": ""
  },
  "short-year": {
    "en": "",
    "ja": "年",
    "et": ""
  }
}
