import React, {useState} from 'react';
import './vivistops.scss';
import Table from '../../elements/table/table';
import Modal from '../../common/modal/modal';

const Vivistops = () => {

  const [show,setShow] = useState(false);

  const sideItems = [];
  const columns = [
    {table: "vivistops",value: "id",hide: true, index:true, },
    {table: "vivistops",value: "uid",hide:true, index:true, },
    {table: "vivistops",value: "name", },
    {table: "vivistops",value: "country", },
    {table: "vivistops",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
  ];

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"publicProfile",leftColumn:"id",rightColumn:"userId",},
  ];

  return (
    <div className="vivistops">
      <h3>VIVISTOPS</h3>
      <Table 
        displayName="Vivistops"
        name="VivistopsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {

        }}
        // refreshInc={refreshInc}
        columns={columns}
        limit={250}
        // newButtonText={"Add VIVISTOP"}
        // newButtonOnClickFunc={() => {
        //   setShow(true);
        // }}
        />
      <Modal show={show} handleClose={() => {setShow(false)}}>
        <div>
          Coming Soon
        </div>
      </Modal>
    </div>
  );

};

export default Vivistops;