import React, { useState, } from "react";
import "./users.scss";
import Modal from "../../common/modal/modal";
import PasswordInput from '../../elements/password-input/password-input';
import Button from "../../elements/button/button";
import { request } from '../../../utils/request';
import {toast} from 'react-toastify';

const NewUserModal = ({show,handleClose}) => {

  const [loading,setLoading] = useState(false);
  const [username,setUsername] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser();
  }
  
  const saveUser = () => {
    let body = {
      username,
      email,
      password,
    }
    setLoading(true);
    request("admin-create-new-user","/admin-create-new-user/","POST", body, {
      then: function(res){ handleClose(true); },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <Modal show={show} handleClose={() => {
      handleClose(false);
    }}>
      <div>
        <h3>Admin Create New User</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username</label>
            <input type="text" value={username} onChange={(e) => {setUsername(e.target.value)}} />
          </div>
          <div>
            <label>Email</label>
            <input type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
          </div>
          <div>
            <label>Password</label>
            <PasswordInput disabled={loading} value={password} placeholder="New Password" onChange={(e) => {setPassword(e.target.value)}}/>
          </div>
          <Button status={(loading)?"loading":"add"} type="submit" value="Create New User" />
        </form>
      </div>
    </Modal>
  );
};

export default NewUserModal;
