import React,{useState,} from 'react';
import './article-modal.scss';
import Modal from '../../common/modal/modal';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {toast} from 'react-toastify';
import {request} from '../../../utils/request';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import usePersistState from '../../../utils/use-persist-state';
import {clone} from '../../../utils/clone';

const ArticleModal = ({show,handleClose,callback}) => {

  const [slide,setSlide] = useState(1);
  const [loading,setLoading] = useState(false);
  const [title,setTitle] = useState("");
  const [articleDetailPrompt,setArticleDetailPrompt] = useState("");
  const [prompt,setPrompt] = usePersistState("","articleModalPromptState");
  const [articles,setArticles] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveNewArticle(title,articleDetailPrompt);
  }
  const handleSubmitTwo = (e) => {
    e.preventDefault();
    createMultipleNewArticles();
  }

  const saveNewArticle = (title,articleDetailPrompt,doNotClose) => {
    let body = {title,articleDetailPrompt,}
    setLoading(true);
    request("save-new-article","/save-new-article","POST", body, {
      then: function(res){
        if(callback){ callback(); }
        if(handleClose && doNotClose !== true){ handleClose(); }
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const saveNewArticles = (articles) => {
    let body = {articles}
    setLoading(true);
    request("save-new-articles","/save-new-articles","POST", body, {
      then: function(res){
        toast.success("Saved Articles");
        if(callback){ callback(); }
        // if(handleClose){ handleClose(); }
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const createMultipleNewArticles = () => {
    let body = {prompt,}
    setLoading(true);
    request("create-multiple-new-articles","/create-multiple-new-articles","POST", body, {
      then: function(res){
        let newJson = (res.data.res.json)?res.data.res.json:"";
        try{
          let parseJson = JSON.parse(newJson);
          console.log("parseJson",parseJson);
          setArticles(parseJson);
        }catch(e){
          console.log("e",e);
        }
        // if(callback){ callback(); }
        // if(handleClose){ handleClose(); }
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  return (
    <Modal className="article-modal" show={show} handleClose={handleClose}>
      <div>
        <CarouselHeader slideTo={slide} forceResize={show}>
          <div onClick={() => {setSlide(1)}}>Create New Article</div>
          <div onClick={() => {setSlide(2)}}>Create Multiple New Articles</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div className='carousel-item'>
            <div className='article-inputs'>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Title</label>
                  <input type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}/>
                </div>
                <div>
                  <label>Article Detail Prompt</label>
                  <Textarea value={articleDetailPrompt} onChange={(e) => {setArticleDetailPrompt(e.target.value)}}></Textarea>
                </div>
                <Button type="submit" status={loading?"loading":"save"} value="Save" />
              </form>
            </div>
          </div>
          <div className='carousel-item'>
            <form onSubmit={handleSubmitTwo}>
              <div>
                <label>Create Multiple Articles Prompt</label>
                <Textarea value={prompt} onChange={(e) => {setPrompt(e.target.value)}}></Textarea>
              </div>
              <Button type="submit" status={loading?"loading":"forward"} value="Generate" />
            </form>

            {articles.length?
            <div className='articles'>
              <div className='header-row'>
                <div className='left'></div>
                <div className='right'>
                  <Button status={loading?"loading":"add"} onClick={() => {
                    let localArticles = [];
                    for (let i = 0; i < articles.length; i++) {
                      let obj = {
                        title:articles[i].articleTitle,
                        desc:articles[i].articleDescription,
                      }
                      localArticles.push(obj);
                    }
                    saveNewArticles(localArticles)
                    setArticles([]);
                  }}>Add All</Button>
                </div>
              </div>
              
              {articles.map((v,i) => {
                return(
                  <div className='article' key={i}>
                    <div className='article-details'>
                      <div className='title'>{v.articleTitle}</div>
                      <div className='desc'>{v.articleDescription}</div>
                    </div>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      let cloneNewArticles = clone(articles);
                      cloneNewArticles.splice(i,1);
                      saveNewArticle(v.articleTitle,v.articleDescription,true)
                      setArticles(cloneNewArticles);
                    }}>Add</Button>
                  </div>
                )
              })}

            </div>
            :null}
            
          </div>
        </Carousel>
      </div>
    </Modal>
  );
};

export default ArticleModal;