
export const dateTimeSinceEpoch = function (epoch,nowSeconds) {
  let foo = parseInt(epoch*1000)
  let d = new Date(foo);
  return dateTimeSince(d,nowSeconds);
}

export const dateTimeSinceWithTime = (dateObj,nowSeconds) => {return dateTimeSince(dateObj,nowSeconds,true)}
export const dateTimeSince = function (dateObj,nowSeconds,withTime) {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let nowSecs = (nowSeconds)?nowSeconds:5;


  let d = new Date(dateObj);
  let t = new Date();
  let dateDiff = t.getTime() - d.getTime();

  var secondsFromD2T = dateDiff / 1000;
  var secondsBetweenDates = Math.abs(secondsFromD2T);

  if(secondsBetweenDates <= nowSecs){
    return "Now";
  }else if(secondsBetweenDates <= 60){
    let seconds = Math.floor(secondsBetweenDates)
    return ((seconds !== 1)? seconds+" Seconds":seconds+" Second")+" Ago";
  }else if(secondsBetweenDates < 3600){
    let minutes = Math.floor(secondsBetweenDates/60);
    return ((minutes > 1)? minutes+" Minutes":minutes+" Minute")+" Ago";
  }else if(secondsBetweenDates < 86400){
    let hours = Math.floor(secondsBetweenDates/60/60);
    return ((hours > 1)? hours+" Hours":hours+" Hour")+" Ago";
  }else if(secondsBetweenDates < 604800){//7 days
    let days = Math.floor(secondsBetweenDates/60/60/24);
    return ((days > 1)? days+" Days":days+" Day")+" Ago";
  }

  if(withTime){
    return prettifyDateWithTime(d);
  }
  return prettifyDate(d);
};

export const prettifyDate = (dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let d = new Date(dateObj);
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 
                'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let formattedDate = months[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear()
  return formattedDate;
}

export const prettifyDateWithTime = (dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let d = new Date(dateObj);
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 
                'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let formattedDate = months[d.getUTCMonth()] + " " + d.getUTCDate() + " " + d.getUTCFullYear() + " ";
  formattedDate += (d.getUTCHours() <= 9)?"0"+d.getUTCHours():d.getUTCHours();
  formattedDate += ":";
  formattedDate += (d.getUTCMinutes() <= 9)?"0"+d.getUTCMinutes():d.getUTCMinutes();
  return formattedDate;
}
export const prettifyDateWithTimeTranslateString = (t,dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let d = new Date(dateObj);
  let months = [t('time.short-month-1'), t('time.short-month-2'), t('time.short-month-3'), t('time.short-month-4'), 
                t('time.short-month-5'), t('time.short-month-6'), t('time.short-month-7'), t('time.short-month-8'),
                t('time.short-month-9'), t('time.short-month-10'), t('time.short-month-11'), t('time.short-month-12')];

  let dayStr = t('time.short-day') === "time.short-day"?"":t('time.short-day');
  let yearStr = t('time.short-year') === "time.short-year"?"":t('time.short-year');
  let formattedDate = months[d.getUTCMonth()] + " " + d.getUTCDate() +dayStr+ " " + d.getUTCFullYear() +yearStr+ " ";
  formattedDate += (d.getUTCHours() <= 9)?"0"+d.getUTCHours():d.getUTCHours();
  formattedDate += ":";
  formattedDate += (d.getUTCMinutes() <= 9)?"0"+d.getUTCMinutes():d.getUTCMinutes();
  return formattedDate;
}

export const epochToDatetimeLocal = (epochTime,offsetTime) => {
  let minute1 = 60000;
  let hour1 = minute1*60;
  let time = parseInt(epochTime);
  time = time - (time % minute1)
  if(offsetTime){
    time = time + (((new Date().getTimezoneOffset())/60) * hour1);
  }
  time = new Date(time);
  time = time.getFullYear()+"-"+(((time.getMonth()+1) <= 9)?"0":"")+(time.getMonth()+1)+"-"+((time.getDate()<=9)?"0":"")+time.getDate()+"T"+((time.getHours()<=9)?"0":"")+time.getHours()+":00";
  return time
}

export const datetimeToEpoch = (date,offsetTime) => {
  let minute1 = 60000;
  let hour1 = minute1*60;
  let epoch = new Date(date).getTime();
  if(offsetTime){
    epoch = epoch - (((new Date().getTimezoneOffset())/60) * hour1);
  }
  return epoch
}


export const dateTimeSinceWithTimeTranslateString = (t,dateObj,nowSeconds) => {return dateTimeSinceTranslateString(t,dateObj,nowSeconds,true)}
export const dateTimeSinceTranslateString = (t,dateObj,nowSeconds,withTime) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let nowSecs = (nowSeconds)?nowSeconds:5;

  let dd = new Date(dateObj);
  let td = new Date();
  let dateDiff = td.getTime() - dd.getTime();

  var secondsFromD2T = dateDiff / 1000;
  var secondsBetweenDates = Math.abs(secondsFromD2T);

  let text = ""
  let value = "";
  if(secondsBetweenDates <= nowSecs){
    text = "time.now";
  }else if(secondsBetweenDates <= 60){
    let seconds = Math.floor(secondsBetweenDates)
    text = ((seconds > 1)?"time.seconds-ago":"time.second-ago");
    value = seconds;
  }else if(secondsBetweenDates < 3600){
    let minutes = Math.floor(secondsBetweenDates/60);
    text = ((minutes > 1)?"time.minutes-ago":"time.minute-ago");
    value = minutes;
  }else if(secondsBetweenDates < 86400){
    let hours = Math.floor(secondsBetweenDates/60/60);
    text = ((hours > 1)?"time.hours-ago":"time.hour-ago");
    value = hours;
  }else if(secondsBetweenDates < 604800){//7 days
    let days = Math.floor(secondsBetweenDates/60/60/24);
    text = ((days > 1)?"time.days-ago":"time.day-ago");
    value = days;
  }

  if(text !== ""){
    return t(text,{value: value});
  }else{
    return prettifyDateWithTimeTranslateString(t, dateObj);
  }

};

export function formatDateForSitemap(dateString) {
  // Parse the input date string
  const date = new Date(dateString);

  // Extract the date components
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;

  return formattedDate;
}