import React from 'react';
import './jobs.scss';

const Jobs = () => {

  return (
    <div className="jobs-comp">
      <h1>jobs-comp</h1>
    </div>
  );

};

export default Jobs;