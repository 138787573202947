import React from 'react';
import './logo.scss';
import MythBlenderWhiteLogo from '../../../css/imgs/logo/mb-t-white.png';
import MythBlenderBlueLogo from '../../../css/imgs/logo/mb-t-blue.png';

const Logo = ({options}) => {
  options = (options)?options:"";
  return (
    <div className={"logo-c "+options}>
      <>
        {(options === "large")?
        <img className='logo-img' src={MythBlenderBlueLogo} />
        :
        <img className='logo-img' src={MythBlenderWhiteLogo} />
        }
        <span className="name">Myth</span>
        <span className="name2">Blender</span>
      </>
    </div>
  );

};

export default Logo;



// import React from 'react';
// import './logo.scss';

// const Logo = ({options}) => {
//   options = (options)?options:"";
//   return (
//     <div className={"logo-c "+options}>
//       <span className="name">Myth</span>
//       <span className="name2">Blender</span>
//     </div>
//   );

// };

// export default Logo;