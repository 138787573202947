import React from 'react';
import {ReactComponent as PeopleSvg} from "../../css/imgs/icon-people.svg";
import {ReactComponent as CustomerSupportSvg} from '../../css/imgs/icon-customer-support.svg';
import {ReactComponent as FeedbackSvg} from '../../css/imgs/icon-feedback.svg';
import {ReactComponent as ToolsSvg} from '../../css/imgs/icon-tools.svg';
import {ReactComponent as LibrarySvg} from "../../css/imgs/icon-library.svg";
import {ReactComponent as GlobeSvg} from '../../css/imgs/icon-globe.svg';
import {ReactComponent as SearchSvg} from '../../css/imgs/icon-seo.svg';

// import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
// import {ReactComponent as UserRolesSvg} from "../../css/imgs/icon-user-roles.svg";
// import {ReactComponent as NotifsSvg} from "../../css/imgs/icon-notifications.svg";
// import {ReactComponent as ForumSvg} from '../../css/imgs/icon-forum.svg';
import {ReactComponent as AwardSvg} from "../../css/imgs/icon-award.svg";
// import {ReactComponent as TrackSvg} from '../../css/imgs/icon-track.svg';
// import {ReactComponent as JobsSvg} from '../../css/imgs/icon-jobs.svg';

import AccountStatusEnum from '../../enums/account-status';


const naviRoutes = [
  // {
  //   "name": "dashboard-nav-item", 
  //   "display": "Dashboard", 
  //   "path": "/dashboard",
  //   "icon": <DashboardSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  {
    "name": "developer-nav-item", 
    "display": "Developer", 
    "path": "/developer",
    "icon": <ToolsSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "users-nav-item", 
    "display": "Users", 
    "path": "/users",
    "icon": <PeopleSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  // {
  //   "name": "vivistops-nav-item", 
  //   "display": "VIVISTOPS", 
  //   "path": "/vivistops",
  //   "icon": <VivistopsSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  // {
  //   "name": "user-roles-nav-item", 
  //   "display": "User Roles", 
  //   "desc": "Main Database",
  //   "path": "/user-roles",
  //   "icon": <UserRolesSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  {
    "name": "globe-nav-item", 
    "display": "Worlds", 
    "desc": "Worlds Database",
    "path": "/worlds",
    "icon": <GlobeSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "library-nav-item", 
    "display": "Library", 
    "desc": "Courses",
    "path": "/library",
    "icon": <LibrarySvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "search-nav-item", 
    "display": "Search", 
    "desc": "Search Database",
    "path": "/search",
    "icon": <SearchSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  // {
  //   "name": "tracks-nav-item", 
  //   "display": "Tracks", 
  //   "desc": "Grouped Courses",
  //   "path": "/tracks",
  //   "icon": <TrackSvg/>,
  //   "minStatus": AccountStatusEnum.Admin,
  // },
  // {
  //   "name": "community-nav-item", 
  //   "display": "Cummunity", 
  //   "desc": "Forum & Projects",
  //   "path": "/community",
  //   "icon": <ForumSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  // {
  //   "name": "jobs-nav-item", 
  //   "display": "Jobs", 
  //   "path": "/jobs",
  //   "icon": <JobsSvg/>,
  //   "minStatus": AccountStatusEnum.Admin,
  // },
  {
    "name": "customer-support-nav-item", 
    "display": "Customer Support", 
    "path": "/customer-support",
    "icon": <CustomerSupportSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "feedback-nav-item", 
    "display": "Feedback", 
    "desc": "Bug Reports",
    "path": "/feedback",
    "icon": <FeedbackSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  // {
  //   "name": "notifs-nav-item", 
  //   "display": "Push Notifications", 
  //   "path": "/push-notifs",
  //   "icon": <NotifsSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },

  {
    "name": "green-machine", 
    "display": "Green Machine", 
    "path": "/green-machine",
    "icon": <AwardSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },

  // {
  //   "name": "test", 
  //   "display": "Test",
  //   "path": "/test",
  //   "minStatus": AccountStatusEnum.Staff,
  // },
];

export default naviRoutes;