import React from 'react';
import './tracks.scss';

const Tracks = () => {

  return (
    <div className="tracks-comp">
      <h3>tracks-comp</h3>
    </div>
  );

};

export default Tracks;