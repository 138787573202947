import React from 'react';
import './tech-degree.scss';

const TechDegree = () => {

  return (
    <div className="tech-degree-comp">
      <h1>Tech Degree comp</h1>
    </div>
  );

};

export default TechDegree;