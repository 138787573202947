import React, { useState, } from "react";
import "./users.scss";
import Config from "../../../config/config";
import Button from "../../elements/button/button";
import { ReactComponent as PersonSvg } from "../../../css/imgs/icon-person.svg";

import useUserState from "../../../store/user";
import Table from '../../elements/table/table';
import AccountStatusEnum,{AccountStatusIdEnum} from "../../../enums/account-status";
import UserModal from "./user-modal";
import { VivistopEnum } from "../../../enums/vivistop";
import NewUserModal from "./new-user-modal";

const Users = () => {

  const { user } = useUserState();
  
  const [showUserModal, setShowUserModal] = useState(false);
  const [showUser, setShowUser] = useState({});
  const [refreshInc,setRefreshInc] = useState(0);
  const [showNewUserModal,setShowNewUserModal] = useState(false);

  const refresh = (refreshInc) => {
    setRefreshInc(refreshInc+1);
  }

  const sideItems = [{display:"Any",conditions:[]}];
  for (let key in AccountStatusEnum) {
    let accountStatus = AccountStatusEnum[key];
    if(AccountStatusEnum.Any === accountStatus) continue;
    sideItems.push({
      display: key,
      conditions:[{
        action: "=",
        column:"status", 
        table:"users",
        value: accountStatus,
      }]
    });
  }

  const columns = [
    {table: "users",value: "id",hide: true, index:true, },
    {table: "users",value: "uid",hide: true, index:true, },
    {table: "users",value: "username",default: true, index:true, 
      custom: ({v,cv}) => {
        let imageUid = v["publicProfileImageUid"]?v["publicProfileImageUid"]:null;
        let displayName = v["publicProfileDisplayName"]?v["publicProfileDisplayName"]:null;
        return(
        <div className={"username-column"}>
          {imageUid?(
            <img src={Config.Common.ApiBaseUrl+"/f/"+imageUid} alt={v.username}/>):(
            <div className="svg-container"><PersonSvg /></div>
          )}
          <div className="text">
            <div className="username">{v[cv]}</div>
            {(displayName)?<div className="display-name">{displayName}</div>:null}
          </div>
          
        </div>
      )},
    },
    {table: "publicProfile",value: "imageUid", hide:true, },
    {table: "publicProfile",value: "displayName", hide:true, },
    {table: "users",value: "email", index:true, },
    {table: "users",value: "status", display:"User Status",index:true, 
      custom: ({v,cv}) => {
        let status = parseInt(v[cv]);
        let statusText = (status in AccountStatusIdEnum)?AccountStatusIdEnum[status]:status;
        return(
          <div>{statusText}</div>
        )
      },  
    },
    {table: "users",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
    {table: "users",value: "lastActive", type:"date",},
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"users",rightTable:"publicProfile",leftColumn:"id",rightColumn:"userId",},
  ]

  return (
    <div className="users-comp">
      <div className="users-comp-header">
        <h3>Users</h3>
      </div>
      <Table 
        displayName="Users"
        name="UsersTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowUser(value);
          setShowUserModal(true);
        }}
        refreshInc={refreshInc}
        columns={columns}
        limit={250}
        newButtonText={"New User"}
        newButtonOnClickFunc={() => {
          setShowNewUserModal(true);
        }}
      />

      <NewUserModal 
        show={showNewUserModal} 
        handleClose={(refresh) => {
          setShowNewUserModal(false)
          setRefreshInc((refresh?refreshInc + 1:refreshInc));
        }}
        />

      <UserModal
        user={user}
        show={showUserModal}
        handleClose={() => {
          setShowUser(null);
          setShowUserModal(false);
        }}
        refresh={() => {refresh(refreshInc);}}
        viewUser={showUser}
      />

    </div>
  );
};

export default Users;
