
import React from 'react';
import './css/styles/app.scss';
import './css/styles/common.scss';
import './css/styles/toastify.scss';

import { Router, Route, Switch, withRouter } from 'react-router-dom';
import history from './store/history';

import Footer from './components/footer/footer';
import Navi from './components/navi/navi';
import EntryLogin from './components/views/entry/entry-login';
import EntryCreateAccount from './components/views/entry/entry-create-account';
import Totp from './components/views/entry/totp/totp';
import TotpReset from './components/views/entry/totp/totp-reset';
import NewDevice from './components/views/entry/new-device/new-device';
import SnsAuth from './components/views/entry/sns-auth/sns-auth';
import Home from './components/views/home/home';
import NotFound from './components/views/not-found/not-found';
import MyAccount from './components/views/my-account/my-account';
import Test from './components/views/test/test';
import Favicon from './components/views/favicon/favicon';
import ResetPassword from './components/views/email-related/reset-password/reset-password';
import VerifyEmail from './components/views/email-related/verify-email/verify-email';
import ChangeEmail from './components/views/email-related/change-email/change-email';

import LoginRoute from './components/common/routes/login-route';
import AuthRoute from './components/common/routes/auth-route';
import NormRoute from './components/common/routes/norm-route';
import ScrollToTop from './components/common/routes/scroll-to-top';

import Dashboard from './components/views/dashboard/dashboard';
import Developer from './components/views/developer/developer';
import TechDegree from './components/views/tech-degree/tech-degree';
import Library from './components/views/library/library';
import Tracks from './components/views/tracks/tracks';
import Community from './components/views/community/community';
import Jobs from './components/views/jobs/jobs';


import Users from './components/views/users/users';
import UserRoles from './components/views/user-roles/user-roles';
import CustomerSupport from './components/views/customer-support/customer-support';
import Feedback from './components/views/feedback/feedback';
import PushNotifs from './components/views/push-notifs/push-notifs';
import Vivistops from './components/views/vivistops/vivistops';
import Search from './components/views/search/search';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';

import AccountStatusEnum from './enums/account-status';
import SnsTypeEnum from './enums/sns-type';

import Settings from './config/settings';
import packageJson from '../package.json';
import {Environment} from './config/env';
import Worlds from './components/views/worlds/worlds';
import GreenMachine from './components/views/green-machine/green-machine';



console.log("App Version: ",packageJson.version);
console.log("React Version: ",React.version);
console.log("Environment: ",Environment);

const App = () => {

  const subDirectory = "/";
  const naviClass = (Settings.TopNavi)?" top-navi":" side-navi";
  const stickyNaviClass = (Settings.StickyNavi)?" sticky-navi":""; 

  const NaviDiv = withRouter(({location}) => {
    // if(location.pathname === "/"){
    //   return (<div></div>)
    // }
    return (
      <Navi />
    )
  })
  const FooterDiv = () => {
    if(!Settings.Footer) return null;
    return (
      <Footer />
    )
  }

  return (
    <Router basename={subDirectory} history={history}>
      <ScrollToTop />
      <div className={"app"+naviClass+stickyNaviClass}>
        <NaviDiv />
        <div id="content-container">
          <div id="content">
            <Switch>
              <NormRoute exact path="/" component={(props) => <Home history={props.history} />} />  
              <LoginRoute path="/login(.*)" component={() => {return <EntryLogin />} } />
              <LoginRoute path="/create-account(.*)" component={() => {return <EntryCreateAccount />} } />
              <LoginRoute path="/totp" component={Totp} />
              <LoginRoute path="/new-device" component={NewDevice} />
              <NormRoute path="/totp-reset" component={TotpReset} />

              <AuthRoute exact path="/dashboard"  minStatus={AccountStatusEnum.Staff}component={Dashboard} />
              <AuthRoute exact path="/developer" minStatus={AccountStatusEnum.Admin} component={Developer} />
              <AuthRoute exact path="/worlds" minStatus={AccountStatusEnum.Admin} component={Worlds} />

              <AuthRoute exact path="/tech-degree" minStatus={AccountStatusEnum.Admin} component={TechDegree} />
              <AuthRoute exact path="/library" minStatus={AccountStatusEnum.Admin} component={Library} />
              <AuthRoute exact path="/tracks" minStatus={AccountStatusEnum.Admin} component={Tracks} />
              <AuthRoute exact path="/community" minStatus={AccountStatusEnum.Admin} component={Community} />
              <AuthRoute exact path="/jobs" minStatus={AccountStatusEnum.Admin} component={Jobs} />

              <AuthRoute exact path="/green-machine" minStatus={AccountStatusEnum.Admin} component={GreenMachine} />


              <AuthRoute exact path="/users" minStatus={AccountStatusEnum.Staff} component={Users} />
              <AuthRoute exact path="/vivistops" minStatus={AccountStatusEnum.Staff} component={Vivistops} />
              <AuthRoute exact path="/search" minStatus={AccountStatusEnum.Staff} component={Search} />

              <AuthRoute exact path="/user-roles"  minStatus={AccountStatusEnum.Staff}component={UserRoles} />
              <AuthRoute exact path="/customer-support" minStatus={AccountStatusEnum.Staff} component={CustomerSupport} />
              <AuthRoute exact path="/feedback" minStatus={AccountStatusEnum.Staff} component={Feedback} />
              <AuthRoute exact path="/push-notifs" minStatus={AccountStatusEnum.Staff} component={PushNotifs} />


              <AuthRoute path="/my-account" minStatus={AccountStatusEnum.Basic} component={MyAccount} />
              <NormRoute path="/test" component={Test} />
              <AuthRoute path="/test-auth" minStatus={AccountStatusEnum.Admin} component={Test} />
              <LoginRoute exact path="/verify-email" component={VerifyEmail} /> 
              <LoginRoute path="/verify-email/:token" component={(props) => <VerifyEmail tokenArg={props.match.params.token}/>} /> 
              <NormRoute exact path="/reset-password" component={ResetPassword} /> 
              <NormRoute path="/reset-password/:token" component={(props) => <ResetPassword tokenArg={props.match.params.token} />} /> 
              <NormRoute exact path="/verify-change-email" component={ChangeEmail} />
              <NormRoute path="/verify-change-email/:token" component={(props) => <ChangeEmail tokenArg={props.match.params.token} />} /> 

              <LoginRoute path="/google-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Google}/>} />
              <LoginRoute path="/facebook-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Facebook}/>} />

              <NormRoute path="/favicon" component={Favicon} />

              <Route component={NotFound} />  
            </Switch>
          </div>
          <FooterDiv/>
        </div>

        <ToastContainer 
          position="top-right"
          autoClose={4000}
          transition={Flip}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </div>
    </Router> 
  );
}

export default App;
