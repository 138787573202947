import React,{useState,useEffect} from 'react';
import './worlds.scss';
import Config from '../../../config/config';
import Table from '../../elements/table/table';
import {ReactComponent as ImageSvg} from '../../../css/imgs/icon-image.svg';
import Modal from '../../common/modal/modal';
import * as DateUtil from '../../../utils/date';
import * as StringUtil from '../../../utils/string';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';

const Worlds = () => {

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(null);
  const [toggle,setToggle] = useState(false);
  const [refreshInc,setRefreshInc] = useState(1);

  let sideItems = [
    {display: "All", conditions:[]},
    {display: "Templates", conditions:[{
      column:"template", table:"worlds", action: "=",value: 1,
    }]},
  ];
  const columns = [
    {table: "worlds",value: "uid",hide: true, index:true, },
    {table: "worlds",value: "name",default: true, index:true, 
      custom: ({v,cv}) => {
        let name = v["worldsName"]?v["worldsName"]:"";
        let imageUrl = v["worldsImageUrl"]?v["worldsImageUrl"]:"";
        if(imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"world-name"}>
            <div className='icon'>
            {imageUrl === ""?<ImageSvg />:
              <img alt={"religionGods image "}
                src={(imageUrl+"-small.png")} 
                />  
              }
            </div>
            <div className='text'>
              {name}
            </div>
          </div>
        )
      },
    },
    {table: "worlds",value: "template",index:true, hide:true,},
    {table: "worlds",value: "userUid",index:true, type:"copy-input", ignoreRowClick:true,},
    {table: "worlds",value: "imageUrl", hide:true, },
    {table: "worlds",value: "lastPrompt", hide:true, },
    {table: "worlds",value: "createdDate", index:true, type:"date", },
    {table: "worlds",value: "updatedDate", index:true, defaultOrder:"DESC",type:"date",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"worlds",rightTable:"users",leftColumn:"userUid",rightColumn:"uid",},
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"vivistopMembers",leftColumn:"uid",rightColumn:"userUid",},
  ]

  useEffect(() => {
    let newToggle = show && show.worldsTemplate && show.worldsTemplate === 1?true:false;
    setToggle(newToggle);
  },[show]);

  const updateToggle = (uid,toggle) => {
    let body = {
      uid: uid,
      toggle: toggle,
    }
    setLoading(true);
    request("update-world-template","/update-world-template","POST", body, {
      then: function(res){
        setShow(null);
        setRefreshInc(refreshInc + 1);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="worlds-comp">
      <h1>Worlds</h1>
      <Table 
        displayName="Worlds"
        name="WorldsTable"
        database={"worlds"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShow(value);
        }}
        columns={columns}
        limit={100}
      />


      <Modal show={show?true:false} handleClose={() => {setShow(null)}}>
        <div>
          <h2>World</h2>
          {show !== null?
            <div className='world-modal-buttons'>
              <div className='left'>
                <a className='button' target='_blank' href={Config.Common.LiveFrontEndUrl+"/world-builder/"+(show && show.worldsUid?show.worldsUid:"")} >View World</a>
              </div>
              <div className='right'>
                <SwitchToggle 
                  width={132}
                  height={40}
                  divisor={8}
                  borderRadius={4}
                  textOn={"Template On"}
                  textOff={"Template Off"}
                  isOn={toggle} 
                  refreshInc={refreshInc}
                  onClickFunc={() => {
                    updateToggle((show && show.worldsUid?show.worldsUid:""),!toggle);
                    setToggle(!toggle);
                  }} 
                  />
              </div>
            </div>
          :null}
          {show !== null?
            <>
              {Object.keys(show).map((keyName, i2) => {
                return(
                  <div className='detail' key={i2}>
                    <label>{StringUtil.prettifyCamelCase(keyName)}</label>
                    {keyName.includes("Date")?
                    <input type="text" value={DateUtil.dateTimeSince(show[keyName])} disabled={true} />
                    :<input type="text" value={show[keyName]} disabled={true} />
                    }
                  </div>
                )
              })}
            </>
          :null}
        </div>
      </Modal>

    </div>
  );

};

export default Worlds;