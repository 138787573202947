import React, { useState } from 'react';
import './library.scss';

import Button from "../../elements/button/button";
import Config from '../../../config/config';
import Table from '../../elements/table/table';
import {CourseStatusIdEnum} from "../../../enums/course-status";
import { ReactComponent as PersonSvg } from "../../../css/imgs/icon-person.svg";
import Modal from '../../common/modal/modal';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';

const Library = () => {

  const [slide,setSlide] = useState(1);
  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [showValue,setShowValue] = useState(null);
  const [refreshInc,setRefreshInc] = useState(1);


  const sideItems = [{display:"All",conditions:[]},];
  for (let key in CourseStatusIdEnum) {
    let courseStatus = CourseStatusIdEnum[key];
    sideItems.push({
      display: courseStatus,
      conditions:[{
        action: "=",
        column:"status", 
        table:"courses",
        value: key,
      }]
    });
  }

  const columns = [
    {table: "courses",value: "uid",hide: true, index:true, },
    {table: "users",value: "username", display:"Creator",
      custom: ({v,cv}) => {
        let imageUid = v["publicProfileImageUid"]?v["publicProfileImageUid"]:null;
        let displayName = v["publicProfileDisplayName"]?v["publicProfileDisplayName"]:null;
        return(
        <div className={"username-column"}>
          {imageUid?(
            <img src={Config.Common.ApiBaseUrl+"/f/"+imageUid} alt={v.username}/>):(
            <div className="svg-container"><PersonSvg /></div>
          )}
          <div className="text">
            <div className="username">{v[cv]}</div>
            {(displayName)?<div className="display-name">{displayName}</div>:null}
          </div>
          
        </div>
      )},
    },
    {table: "publicProfile",value: "imageUid", hide:true, },
    {table: "publicProfile",value: "displayName", hide:true, },
    {table: "courses",value: "title", },
    {table: "courses",value: "tags", },
    {table: "courses",value: "desc", hide:true, },
    {table: "courses",value: "status", index:true,  
      custom: ({v,cv}) => {
        return(
        <div className={""}>
          {v[cv] in CourseStatusIdEnum?CourseStatusIdEnum[v[cv]]:v[cv]}
        </div>
      )},
    },
    {table: "courses",value: "bulletPointList", hide:true, },
    {table: "courses",value: "category", index:true, },
    {table: "courses",value: "difficulty",  },
    {table: "courses",value: "listedDate", index:true, type:"date", },
    {table: "courses",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
    {table: "courses",value: "updatedDate", type:"date",},
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"courses",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
    {joinType:"LEFT JOIN",leftTable:"courses",rightTable:"publicProfile",leftColumn:"userId",rightColumn:"userId",},
  ]

  const rejectCourse = (uid) => {
    setLoading(true);
    request("reject-course","/course/"+uid+"/reject","POST", {}, {
      then: function(res){
        toast.success("Course Rejected");
        setLoading(false);
        setRefreshInc(refreshInc + 1);
        setShow(false);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const approveCourse = (uid) => {
    setLoading(true);
    request("approve-course","/course/"+uid+"/approve","POST", {}, {
      then: function(res){
        toast.success("Course Approved");
        setLoading(false);
        setRefreshInc(refreshInc + 1);
        setShow(false);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const listCourse = (uid) => {
    setLoading(true);
    request("approve-course","/course/"+uid+"/list","POST", {}, {
      then: function(res){
        toast.success("Course Approved");
        setLoading(false);
        setRefreshInc(refreshInc + 1);
        setShow(false);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  return (
    <div className="library-comp">
      <h3>Library</h3>

      <Table 
        displayName="Library"
        name="LibraryTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShow(true);
          setShowValue(value);
        }}
        refreshInc={refreshInc}
        columns={columns}
        limit={250}
      />



      <Modal className="librar-main-modal" show={show} handleClose={() => {setShow(false);setShowValue(null)}}>
        <div className='libary-modal'>

          <CarouselHeader slideTo={slide} forceResize={show}>
            <div onClick={()=>{setSlide(1)}}>Course Details</div>
            <div onClick={()=>{setSlide(2)}}>Maker Messages</div>
          </CarouselHeader>
          <Carousel slideTo={slide}>
            <div className='course-details'>
              <a target={"_blank"}  rel="noreferrer"
                href={showValue && showValue.coursesUid?Config.Common.LiveFrontEndUrl+"/course/course_"+showValue.coursesUid:""}>
                View Course
              </a>
              {showValue?Object.keys(showValue).map((v, i) => {
                return(
                  <div className="" key={i}>
                    <div>{v}: {showValue[v]}</div>
                  </div>
                )
              }):null}

              <div className='buttons'>

                <div className='left'><Button status={loading?"loading":""} onClick={() => {
                  rejectCourse(showValue.coursesUid);
                }}>Reject</Button></div>
                <div className='right'>
                  <Button status={loading?"loading":""} onClick={() => {
                    approveCourse(showValue.coursesUid);
                  }}>Approve</Button>
                  <Button status={loading?"loading":""} onClick={() => {
                    listCourse(showValue.coursesUid);
                  }}>Publish</Button>
                </div>
              </div>

            </div>
            <div>
              Will be able to communicate with the creator on why/why not the course was rejected/approved.
            </div>
          </Carousel>

        </div>
      </Modal>
    </div>
  );

};

export default Library;