import React from 'react';
import './community.scss';

import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';


const Community = () => {

  return (
    <div className="community-comp">

      <h1>community-comp</h1>
      {/* Projects */}
      {/* <CarouselHeader>
        <div>Forum</div>
        <div>Projects</div>
      </CarouselHeader>
      <Carousel>
        <div>Forum</div>
        <div>Projects</div>
      </Carousel> */}
    </div>
  );

};

export default Community;